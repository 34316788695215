import {
  faHandHoldingUsd,
  faPercentage,
  faShieldAlt,
  faSmile
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './terceirizacaoInfo.css'
import Button from 'view/components/button/button'
import { useHistory } from 'react-router-dom'

const TerceirizacaoInfo: React.FC = () => {
  const history = useHistory()

  function onClick() {
    history.push('/terceirizacao-de-frota')
  }

  return (
    <div className="home-terceirizacao-info">
      <div className="container">
        <div className="home-terceirizacao-info-content">
          <div>
            <h2 className="font-emphasis">Terceirização de frota.</h2>
            <p>
              Mais previsibilidade nos custos, menos gastos com frota própria.
              Terceirize com a Realiza: a mais de 30 anos no mercado trazendo
              economia e liberdade para você focar no seu negócio!
            </p>
            <Button
              className="terceirizacao-info-saiba-mais"
              style="transparent"
              size="sm"
              onClick={onClick}
            >
              Saiba mais
            </Button>
          </div>
          <div>
            <div>
              <FontAwesomeIcon icon={faHandHoldingUsd} />
              <div>
                <h3 className="font-exo">Previsibilidade de custos</h3>
                <p>
                  Na terceirização, os custos são previsíveis, enquanto na frota
                  própria, são variáveis e imprevisíveis.
                </p>
              </div>
            </div>
            <div>
              <FontAwesomeIcon icon={faPercentage} />
              <div>
                <h3 className="font-exo">Redução de custos operacionais</h3>
                <p>
                  Nós cuidamos das documentações veiculares, proteções,
                  manutenções preventivas e corretivas, e outros benefícios.
                </p>
              </div>
            </div>
            <div>
              <FontAwesomeIcon icon={faShieldAlt} />
              <div>
                <h3 className="font-exo">Rastreamento</h3>
                <p>
                  Vantagens como segurança, monitoramento em tempo real dos
                  veículos e controle da frota.
                </p>
              </div>
            </div>
            <div>
              <FontAwesomeIcon icon={faSmile} />
              <div>
                <h3 className="font-exo">Satisfação do cliente</h3>
                <p>
                  Conte com um atendimento de ponta e humanizado da nossa
                  equipe.
                </p>
              </div>
            </div>
            <Button
              className="terceirizacao-info-saiba-mais"
              style="transparent"
              size="sm"
              onClick={onClick}
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TerceirizacaoInfo
